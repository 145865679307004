<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <filters-side-sheet
          :expand-table-filters.sync="expandTableFilters"
          :loading-data="loadingData"
          :filters-are-active="filtersAreActive"
        >
          <template v-slot:title>
            {{ $t('reports.filters') }}
          </template>
          <template v-slot:filters>
            <document-reports-filters
              :loading-data="loadingData"
              :expand-table-filters.sync="expandTableFilters"
              :active-filters.sync="activeFilters"
            />
          </template>
        </filters-side-sheet>
        <table-toolbar
          :context="tableToolbarContext"
          :loading-data="loadingData"
          :expand-table-filters.sync="expandTableFilters"
          :filters-are-active="filtersAreActive"
          @refreshData="getTableData"
        />
        <card-layout
          title-class="caption"
          content-class="pt-0"
          :disable-title="!filtersAreActive"
        >
          <template v-slot:title>
            <filter-chips :active-filters="activeFilters">
              <template v-slot:label="{ item }">
                {{ $t('reports.' + item) }}
              </template>
            </filter-chips>
          </template>
          <template v-slot:content>
            <v-data-table
              :loading="loadingData"
              :headers="headers"
              :items="tableData.items"
              :options.sync="paginationOptions"
              :server-items-length="tableData.itemCount"
              :style="tableWrapStyle"
              :footer-props="{
                itemsPerPageOptions: paginationOptions.itemsRange,
                showCurrentPage: true,
                showFirstLastPage: true,
                disablePagination: loadingData,
                disableItemsPerPage: loadingData
              }"
            >
              <template v-slot:item.status="{ item }">
                <status-chip
                  small
                  :status="item.status"
                />
              </template>
              <template v-slot:item.schedule="{ item }">
                {{ getScheduleString(item.schedule) }}
              </template>
              <template v-slot:item.documentCreatedFrom="{ item }">
                {{ getDateTimeValue(item.documentCreatedFrom, 'long') }}
              </template>
              <template v-slot:item.documentCreatedTo="{ item }">
                {{ getDateTimeValue(item.documentCreatedTo, 'long') }}
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ getDateTimeValue(item.createdAt, 'long') }}
              </template>
              <template v-slot:item.completedAt="{ item }">
                {{ getDateTimeValue(item.completedAt, 'long') }}
              </template>
              <template v-slot:item.actions="{ item }">
                <table-button
                  :link="{
                    name: 'DocumentReportsDetails',
                    params: {
                      routeDetailId: item.id
                    }
                  }"
                  text="general.details"
                />
              </template>
            </v-data-table>
          </template>
        </card-layout>
      </v-col>
    </v-row>
    <reports-create-dialog
      @refresh="getTableData"
    />
  </v-container>
</template>

<script>
import api from '@/api/modules/reports'
import { listViews } from '@/mixins/list_views'
import { getDateTimeValue } from '@/mixins/dates'
import { getScheduleString } from '@/mixins/reports'
import CardLayout from '@/components/layout/CardLayout'
import StatusChip from '@/components/chips/StatusChip'
import FiltersSideSheet from '@/components/side_sheets/FiltersSideSheet'
import DocumentReportsFilters from '@/components/filters/reports/Filters'
import FilterChips from '@/components/chips/FilterChips'
import TableToolbar from '@/components/toolbars/TableToolbar'
import TableButton from '@/components/buttons/TableButton'
import ReportsCreateDialog from '@/components/modals/reports/Create'

export default {
  name: 'DocumentReportsList',
  components: {
    CardLayout,
    StatusChip,
    FiltersSideSheet,
    DocumentReportsFilters,
    FilterChips,
    TableToolbar,
    TableButton,
    ReportsCreateDialog
  },
  mixins: [
    listViews,
    getDateTimeValue,
    getScheduleString
  ],
  computed: {
    headers () {
      return [
        { text: this.$t('general.details'), sortable: false, value: 'actions', width: 48 },
        { text: this.$t('reports.schedule'), sortable: false, value: 'schedule' },
        { text: this.$t('reports.status'), sortable: false, value: 'status' },
        { text: this.$t('reports.documentCreatedFrom'), sortable: false, value: 'documentCreatedFrom' },
        { text: this.$t('reports.documentCreatedTo'), sortable: false, value: 'documentCreatedTo' },
        { text: this.$t('reports.format'), sortable: false, value: 'format' },
        { text: this.$t('reports.completedAt'), sortable: false, value: 'completedAt' },
        { text: this.$t('reports.createdAt'), sortable: false, value: 'createdAt' }
      ]
    }
  },
  methods: {
    async getTableData () {
      const params = {
        ...this.paginationOptions,
        activeFilters: Object.assign({}, this.activeFilters)
      }
      this.loadingData = true
      const result = await api.fetchList(params)
      this.tableData = { ...result }
      this.loadingData = false
    }
  }
}
</script>
