<template>
  <create-modal-layout
    :loading="loading"
    :success="success"
    :error="error"
    :mode="mode"
    :form-valid="formValid"
    :dialog-state.sync="dialogState"
    i18n-context="reports"
    @form:submit="submitForm"
  >
    <template v-slot:form>
      <v-form
        v-show="!success && !error"
        ref="formReportsCreate"
        v-model="formValid"
        lazy-validation
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <date-time-picker
                    :date-picker-props="documentCreatedFromProps"
                    :date-time-string.sync="form.documentCreatedFrom"
                    :rules="[rules.required, rules.maxFromDate, rules.minFromDate]"
                    :hint="documentCreatedFromProps.hint"
                    :show-current="false"
                    :min-date="minFromDate"
                    :max-date="maxFromDate"
                    @close="documentCreatedFromProps.menu = false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <date-time-picker
                    :date-picker-props="documentCreatedToProps"
                    :date-time-string.sync="form.documentCreatedTo"
                    :rules="[rules.required, rules.maxToDate]"
                    :show-current="false"
                    :max-date="maxToDate"
                    :autofocus="true"
                    @close="documentCreatedToProps.menu = false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <custom-select
                    v-model="form.format"
                    outlined
                    :label="$t('reports.create.format') + ' *'"
                    :hint="$t('reports.create.formatHint')"
                    :items="['csv']"
                    persistent-hint
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="showPreview"
              cols="12"
              lg="auto"
            >
              <v-divider
                class="mx-4"
                :vertical="$vuetify.breakpoint.lgAndUp"
              />
            </v-col>
            <v-col v-if="showPreview">
              <report-preview-calendar :get-period="getPeriod" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </create-modal-layout>
</template>

<script>
import api from '@/api/modules/reports'
import { required } from '@/mixins/validation'
import {
  dialogTriggers,
  dialogData
} from '@/mixins/dialogs'
import { DateTime } from 'luxon'
import DateTimePicker from '@/components/create/inputs/pickers/DateTime'
import CreateModalLayout from '@/components/modals/CreateLayout'
import ReportPreviewCalendar from '@/components/modals/reports/calendar/ReportPreviewCalendar'
import CustomSelect from '@/components/inputs/CustomSelect'

export default {
  name: 'ReportsCreateDialog',
  components: {
    DateTimePicker,
    CreateModalLayout,
    ReportPreviewCalendar,
    CustomSelect
  },
  mixins: [
    required,
    dialogTriggers,
    dialogData
  ],
  data () {
    return {
      submitDisabled: false,
      formValid: null,
      loading: false,
      success: false,
      error: false,
      form: {
        documentCreatedFrom: DateTime.local().toISO(),
        documentCreatedTo: DateTime.local().toISO(),
        format: 'csv'
      },
      documentCreatedFromProps: {
        menu: false,
        label: 'reports.create.documentCreatedFrom',
        hint: 'reports.create.documentCreatedFromHint'
      },
      documentCreatedToProps: {
        menu: false,
        label: 'reports.create.documentCreatedTo',
        hint: 'reports.create.documentCreatedToHint'
      },
      rules: {
        maxToDate: v => {
          const getDiff = v => {
            const parsedValue = DateTime.fromFormat(v, 'yyyy-LL-dd - HH:mm', { zone: 'Europe/Sofia' })
            const parsedMaxDate = DateTime.fromISO(this.maxToDate, { zone: 'Europe/Sofia' })
            return parsedMaxDate.diff(parsedValue).as('days') >= 0
          }
          return !v || getDiff(v) || this.$t('reports.create.maxDateTo')
        },
        maxFromDate: v => {
          const getDiff = v => {
            const parsedValue = DateTime.fromFormat(v, 'yyyy-LL-dd - HH:mm', { zone: 'Europe/Sofia' })
            const parsedMaxDate = DateTime.fromISO(this.maxFromDate, { zone: 'Europe/Sofia' })
            return parsedMaxDate.diff(parsedValue).as('days') >= 0
          }
          return !v || getDiff(v) || this.$t('reports.create.maxDateFrom')
        },
        minFromDate: v => {
          const getDiff = v => {
            const parsedValue = DateTime.fromFormat(v, 'yyyy-LL-dd - HH:mm', { zone: 'Europe/Sofia' })
            const parsedMinDate = DateTime.fromISO(this.minFromDate, { zone: 'Europe/Sofia' })
            return parsedValue.diff(parsedMinDate).as('days') >= 0
          }
          return !v || getDiff(v) || this.$t('reports.create.minDateFrom')
        }
      }
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    showPreview () {
      return this.getPeriod.start.isValid && this.getPeriod.end.isValid
    },
    maxToDate () {
      return DateTime.local().toISO()
    },
    maxFromDate () {
      return DateTime.fromISO(this.form.documentCreatedTo).minus({ days: 7 }).toISO()
    },
    minFromDate () {
      return DateTime.fromISO(this.form.documentCreatedTo).minus({ years: 1 }).toISO()
    },
    showFormValidityAlert () {
      return this.formValid === false &&
      (!this.documentCreatedFromProps.menu && !this.documentCreatedToProps.menu)
    },
    getPeriod () {
      return {
        start: DateTime.fromISO(this.form.documentCreatedFrom),
        end: DateTime.fromISO(this.form.documentCreatedTo)
      }
    },
    disablePeriodPreview () {
      return this.getPeriod.length === 0
    }
  },
  watch: {
    'form.documentCreatedTo' () {
      if (this.$refs.formReportsCreate) this.$refs.formReportsCreate.validate()
    }
  },
  methods: {
    openInCreateMode () {
      this.resetState()
      this.dialogState = true
    },
    openInEditMode () {},
    resetState () {
      this.form.documentCreatedFrom = DateTime.local().startOf('day').minus({ days: 7 }).toFormat("yyyy-LL-dd'T'HH:mm")
      this.form.documentCreatedTo = DateTime.local().startOf('day').toFormat("yyyy-LL-dd'T'HH:mm")
      this.success = false
      this.error = false
      this.formValid = null
      this.submitDisabled = false
      if (this.$refs.formReportsCreate) {
        this.$refs.formReportsCreate.resetValidation()
      }
    },
    async submitForm () {
      if (this.$refs.formReportsCreate.validate()) {
        this.loading = true

        try {
          const response = await api.submitData({ ...this.form })
          this.loading = false
          if (response.status >= 200 && response.status < 300) {
            this.success = true
            this.$emit('refresh')
          } else {
            this.error = true
          }
        } catch (error) {
          this.loading = false
          this.error = true
        }
      }
    }
  }
}
</script>
