<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdDateFromProps"
        :data="form"
        :locale="locale"
        reference="createdDateFrom"
        @close="createdDateFromProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdDateToProps"
        :data="form"
        :locale="locale"
        reference="createdDateTo"
        @close="createdDateToProps.menu = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  props,
  mounted,
  beforeDestroy,
  computedProperties,
  commonMethods
} from '@/mixins/filters'
import DatePicker from './picker/Date'

export default {
  name: 'DocumentReportsFilters',
  components: { DatePicker },
  mixins: [
    props,
    mounted,
    beforeDestroy,
    computedProperties,
    commonMethods
  ],
  data () {
    return {
      form: {
        createdAtFrom: null,
        createdAtTo: null
      },
      createdDateFromProps: {
        menu: false,
        label: 'reports.createdDateFrom'
      },
      createdDateToProps: {
        menu: false,
        label: 'reports.createdDateTo'
      }
    }
  }
}
</script>
